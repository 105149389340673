<template>
  <div>
    <vs-card class="m-8">
      <div class="mb-4 flex items-center justify-between">
        <div class="vx-breadcrumb">
          <ul class="flex flex-wrap items-center">
            <li class="inline-flex items-end">
              <router-link :to="{ name: 'newsletters'}">Newsletters</router-link>
              <span class="breadcrumb-separator mx-2">
                    <span class="feather-icon select-none relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                             stroke-linejoin="round" class="feather feather-chevrons-right w-4 h-4"><polyline
                          points="13 17 18 12 13 7"></polyline><polyline points="6 17 11 12 6 7"></polyline></svg>
                    </span>
                </span>
            </li>
            <li class="inline-flex items-center">
              <a href="javascript:void(0)" class="">{{title}}</a>
            </li>
          </ul>
        </div>
        <div class="flex mb-5" v-if="newsletterData.hasPublished === false">
          <div class="ml-auto">
            <vs-button type="border" @click="viewHandler(newsletterData)">Edit</vs-button>
            <vs-button class="ml-4" @click="sendToParents">Send to parents</vs-button>
          </div>
        </div>
      </div>

      <div class="p-6">

        <h2 class="w-full mb-5">{{ date }}</h2>
        <p class="w-full mb-5" v-if="newsletterData"><strong>Author: </strong>{{ newsletterData.authorDetails ?
          newsletterData.authorDetails[0].fullName : '' | capitalize }}</p>
        <h2 class="font-bold text-black">{{ titleText | capitalize }}</h2>
        <p class="w-full mb-5" v-html="newsletterData.introduction"></p>
        <div class="mb-base photo-wrapper">
          <ul class="photos-list">
            <li
              v-for="(image, index) in media"
              :key="index"
              style="display: inline-block"
            >
            <a
                href="javascript::void(0)"
                @click.prevent="openGallery(index)"
              >
              <img
                v-lazy="image.src || image.thumb"
                style="height: 100px" class="object-cover"
              >
              </a>
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <template
          v-if="activities.length>1"
          v-for="(activity, key) in activities"
        >
          <div class="flex items-center mb-5 mt-10" :key="key + 0.1">
            <strong>{{ activity.name }}</strong>
          </div>
          <div class="w-full mt-10 mb-5" :key="key + 0.2">
            <ul class="flex">
              <li class="pr-5 md:pr-10 text-center">
                <strong>Core Skills</strong>
                <img
                  :src="
                    activity.coreSkills.icon
                      ? activity.coreSkills.icon
                      : $defaultImage
                  "
                  :alt="activity.coreSkills.name"
                  class="h-16 object-contain block mx-auto mt-5"
                />
                 <strong>{{ activity.coreSkills ? activity.coreSkills.name : '' }}</strong>
              </li>
              <li class="px-5 md:px-10 text-center">
                <strong>Ways of Learning</strong>
                <img
                  :src="
                    activity.waysOfLearnings.icon
                      ? activity.waysOfLearnings.icon
                      : $defaultImage
                  "
                  :alt="activity.waysOfLearnings.name"
                  class="h-16 object-contain block mx-auto mt-5"
                />
                <strong>{{ activity.waysOfLearnings ? activity.waysOfLearnings.name : '' }}</strong>
              </li>
              <li class="pl-5 md:pl-10 text-center">
                <strong>STREAM</strong>
                <img
                  :src="
                    activity.streams.icon
                      ? activity.streams.icon
                      : $defaultImage
                  "
                  :alt="activity.streams.name"
                  class="h-16 object-contain block mx-auto mt-5"
                />
                <strong>{{ activity.streams ? activity.streams.name : '' }}</strong>
              </li>
            </ul>
          </div>
          <p class="md" :key="key + 0.7">{{ activity.purposeStatement }}</p>

          <div
            class="flex flex-wrap ph-img-list-wrap ph-5-col"
            :key="key + 0.6"
            v-if="activity.photos.length"
          >
            <div
              v-for="(photo, index) in activity.photos"
              :key="photo._id"
              class="ph-img-list"
            >
              <a
                href="javascript::void(0)"
                @click.prevent="openGallery(index, key)"
              >
                <img
                  :src="photo.photo"
                  class="responsive h-64 object-contain"
                />
              </a>
            </div>
          </div>
        </template>
          </ul>
        </div>
        <p class="w-full mb-5" v-html="newsletterData.existText"></p>
      </div>
    </vs-card>
    <LightBox
        ref="lightbox"
        :media="media"
        :show-light-box="false"
        :show-caption="false"
        :show-thumbs="false"
        @onImageChanged="onImageChanged"
      />
  </div>

</template>

<script>
  import {mapActions} from "vuex";
  import moment from "moment";
  import Swal from "sweetalert2/dist/sweetalert2";
  import "sweetalert2/src/sweetalert2.scss";
  import LightBox from "vue-image-lightbox";
import "vue-image-lightbox/dist/vue-image-lightbox.min.css";

export default {
  components: {
    LightBox
  },
    data() {
      return {
        title: '',
        dataId: '',
        newsletterData: {},
        date: '',
        titleText: '',
        currentImageIndex: 0,
        media: [],
        activityMedia: [],
        activities: []
      }
    },
    methods: {
      ...mapActions("newsletter", [
        "fetchNewsletterById",
        "sendNewsletterToParents"
      ]),
      openGallery(index) {
        this.$refs.lightbox.showImage(index);
        this.currentImageIndex = index;
      },
      onImageChanged(index) {
        this.currentImageIndex = index;
      },
      moment(date) {
        return moment(date)
      },
      async getNewsletterDetails(id) {
        await this.fetchNewsletterById(id).then(async res => {
          this.newsletterData = await res.data.data;
          let str = this.newsletterData.title.split(' ');
          this.date = str[0];
          str = str.slice(1);
          this.titleText = str.join(' ');
          this.media = await this.newsletterData.photos.map((item) => {
            return {
              thumb: item.photo,
              src: item.photo,
              srcset: item.photo
            }
          });
          this.activities = this.newsletterData.activitiesList;
        })
      },
      viewHandler(data) {
        this.$router.push({name: 'newsletters-edit', params: {id: data._id}, query: {title: data.title}});
      },
      sendToParents() {
        Swal.fire({
          title: "Are you sure you want to send this newsletter?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, send it!",
        }).then(async (result) => {
          if (result.value) {
            const info = {
              id: this.newsletterData._id,
              rooms: this.newsletterData.rooms
            };
            this.$vs.loading();
            this.sendNewsletterToParents(info).then(res => {
              this.$vs.loading.close();
              this.showMessage(
                "Success",
                "Newsletter sent to parents successfully.",
                "success"
              );
              this.$router.push({name: 'newsletters'});
            })
              .catch(err => {
                this.$vs.loading.close();
                this.showMessage(
                  "Failure",
                  "Newsletter sent to parents failed.",
                  "danger"
                );
              });
          }
        });
      },
      showMessage(title, message, color) {
        this.$vs.notify({
          title: title,
          text: message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: color,
        });
      },
    },
    created() {
      this.title = this.$route.query.title;
      this.dataId = this.$route.params.id;
      this.getNewsletterDetails(this.dataId);
    }
  };
</script>
